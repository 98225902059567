body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  white-space: pre-line;
  font-family: "Roboto", sans-serif;
  color: #0e0b16;
  background-color: azure;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 600px;

  display: grid;
  grid-template-rows: 1fr 1fr 2fr;
  justify-content: center;
  font-size: larger;

  // div {
  //   border: 1px dashed black;
  // }
  .field  {
    td:first-of-type {
      text-align: right;
    }
  }
  .set {
    .form-container{
      display: flex;
      justify-content: space-evenly;
     
    }
  }
  .main {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      height: 120px;
      width: 120px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #0e0b16;

      &:disabled {
        opacity: 0.6;
      }
    }
    .circle {
      border-radius: 50%;
    }
    .start {
      background-color: greenyellow;
    }
    .stop {
      background-color: yellow;
    }
    .rec {
      background-color: red;
    }
    .snap {
      background-color: blueviolet;
    }
    .text {
      font-size: 24px;
    }
  }
  .adjust {
    display: flex;
    flex-direction: column;
    align-items: center;
    select {
      padding: 8px;
    }
  }
  @media only screen and (max-width: 600px){
    .form-container{

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
